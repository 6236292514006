<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="nt-leadsform-modal" @close="notifyCancel">
        <div class="modal-dialog modal-fullscreen-sm-down" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ modalTitle }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body form">
                    <p>{{ modalInfo }}</p>

                    <div class="mb-3">
                        <label class="form-label mb-1">{{ customerTypePrompt }}</label>
                        <div class="radio d-flex">
                            <div class="me-5">
                                <label>
                                    <input type="radio" name="type1" v-model="customerType" value="1" tabindex="5" />
                                    {{ customerTypeCompany }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" name="type2" v-model="customerType" value="2" tabindex="6" />
                                    {{ customerTypePrivate }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex space">
                        <div class="mb-3 me-3 flex-fill">
                            <label class="form-label mb-1">{{ firstNamePrompt }} <span class="text-danger">*</span></label>
                            <input v-model="firstName" autocomplete="off" maxlength="30" class="form-control" id="LeadsFirstName" name="LeadsFirstName" tabindex="10" type="text">
                            <span class="text-danger" v-show="firstNameValid == false && firstNameValidated">{{ firstNameError }}</span>
                        </div>

                        <div class="mb-3 flex-fill">
                            <label class="form-label mb-1">{{ lastNamePrompt }} <span class="text-danger">*</span></label>
                            <input v-model="lastName" autocomplete="off" maxlength="30" class="form-control" id="LeadsLastName" name="LeadsLastName" tabindex="20" type="text">
                            <span class="text-danger" v-show="lastNameValid == false && lastNameValidated">{{ lastNameError }}</span>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label mb-1">{{ emailPrompt }} <span class="text-danger">*</span></label>
                        <input v-model="email" autocomplete="off" maxlength="64" class="form-control" id="LeadsEmail" name="LeadsEmail" tabindex="30" type="text">
                        <span class="text-danger" v-show="emailValid == false && emailValidated">{{ emailError }}</span>
                    </div>

                    <div class="mb-3">
                        <label class="form-label mb-1">{{ functionPrompt }} <span class="text-danger">*</span></label>
                        <select v-model="functie" class="form-control" id="LeadsFunction" name="LeadsFunction" tabindex="35">
                            <option value="">{{ functionSelect }}</option>
                            <option v-for="functie in functions" v-bind:value="functie.code">{{ functie.name }}</option>
                        </select>
                        <span class="text-danger" v-show="functionValid == false && functionValidated">{{ functionError }}</span>
                    </div>

                    <div class="mb-3">
                        <label class="form-label mb-1">{{ companyPrompt }} <span class="text-danger">*</span></label>
                        <input v-model="company" autocomplete="off" maxlength="100" class="form-control" id="LeadsCompany" name="LeadsCompany" tabindex="40" type="text" v-bind:disabled="customerType == 2">
                        <span class="text-danger" v-show="companyValid == false && companyValidated">{{ companyError }}</span>
                    </div>

                    <div class="mb-3">
                        <label class="form-label mb-1">{{ zipPrompt }} <span class="text-danger">*</span></label>
                        <input v-model="zip" autocomplete="off" maxlength="10" class="form-control" id="LeadsZip" name="LeadsZip" tabindex="42" type="text" v-bind:disabled="customerType == 2">
                        <span class="text-danger" v-show="zipValid == false && zipValidated">{{ zipError }}</span>
                    </div>


                    <div class="mb-3">
                        <label class="form-label mb-1">{{ activityPrompt }} <span class="text-danger">*</span></label>
                        <select v-model="activity" class="form-control" id="LeadsActivity" name="LeadsActivity" tabindex="45" v-bind:disabled="customerType == 2">
                            <option value="">{{ activitySelect }}</option>
                            <option v-for="sector in sectors" v-bind:value="sector.code">{{sector.name}}</option>
                        </select>
                        <span class="text-danger" v-show="activityValid == false && activityValidated">{{ activityError }}</span>
                    </div>

                    <div class="mb-3">
                        <div class="form-label mb-1" v-show="globalMessage">
                            <div class="alert alert-success" role="alert" id="LeadsErrorMessage">
                                {{ globalMessage }}
                                <br />
                                <span v-show="showDownload">{{ downloadPrompt }} <a target="_blank" v-bind:href="targetUrl" data-bs-dismiss="modal">{{ downloadTf }}</a></span>
                            </div>
                        </div>
                    </div>


                </div> <!-- /.modal-body -->
                <div class="modal-footer">
                    <div class="pull-left" v-show="isBusy"><i class="fa fa-circle-o-notch fa-spin"></i></div>
                    <button type="button" class="btn btn-secondary" v-bind:disabled="canSubmit === false" v-on:click="submitForm">{{ downloadButton }}</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>


<script lang="ts">
    import { defineComponent } from "vue";
    import $ from "jquery";
    import axios from "axios";
    import { ContactLeadData, ContactLeadResponse } from "../scripts/models/contact-lead-data";
    import SSO from "../scripts/components/sso";
    import Multilang from "../scripts/utils/multilang";
    import { Modal } from "bootstrap";
    const emailRegExp: RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const ActivityPrivate: string = "H01";

    class Sector {
        id!: number;
        code!: string;
        name!: string;
    }

    class Function {
        id!: number;
        code!: string;
        name!: string;
    }

    // https://localhost:5001/nl-be/v2/69/gy-deksel-b125-km-400x-400mm-vo-330x-330mm-h30

    interface ComponentData {
        firstName: string;
        lastName: string;
        email: string;
        company: string;
        zip: string;
        activity: string;
        customerType: number;
        functie: string;

        lastNameValid: boolean,
        firstNameValid: boolean,
        activityValid: boolean,
        emailValid: boolean,
        companyValid: boolean,
        zipValid: boolean,
        functionValid: boolean,

        globalMessage: string,
        isBusy: boolean,

        activityValidated: boolean,
        lastNameValidated: boolean,
        firstNameValidated: boolean,
        emailValidated: boolean,
        companyValidated: boolean,
        zipValidated: boolean,
        functionValidated: boolean,

        showDownload: boolean,

        targetUrl: string,

        title: string,
        info: string,
        titleForHauraton: string,
        infoForHauraton: string,

        downloadButton: string,
        customerTypePrompt: string,

        customerTypeCompany: string,
        customerTypePrivate: string,

        firstNamePrompt: string,
        firstNameError: string,

        lastNamePrompt: string,
        lastNameError: string,

        emailPrompt: string,
        emailError: string,

        companyPrompt: string,
        companyError: string,

        zipPrompt: string,
        zipError: string,

        activityPrompt: string,
        activityError: string,
        activitySelect: string,

        functionPrompt: string,
        functionError: string,
        functionSelect: string,

        downloadPrompt: string,
        downloadTf: string,

        sectors: Sector[],
        functions: Function[],

        initiateReason: "product_document" | "hauraton_pdf" | "hauraton_order"
    }

    export default defineComponent({
        name: "LeadsForm",
        data() {
            return {
                sectors: [],
                functions: [],
                initiateReason: "product_document",

                firstName: "",
                lastName: "",
                email: "",
                company: "",
                zip: "",
                activity: "",
                functie: "",
                customerType: 1,

                lastNameValid: false,
                firstNameValid: false,
                activityValid: false,
                emailValid: false,
                companyValid: false,
                zipValid: false,
                functionValid: false,

                globalMessage: "",
                isBusy: false,

                activityValidated: false,
                lastNameValidated: false,
                firstNameValidated: false,
                emailValidated: false,
                companyValidated: false,
                zipValidated: false,
                functionValidated: false,

                showDownload: false,

                targetUrl: "",

                title: Multilang.getTranslation("leads.modal.title", "Download technische fiche"),
                info: Multilang.getTranslation("leads.modal.info", "Vul het downloadformulier op deze pagina in en u krijgt niet enkel toegang tot de technische fiche van dit product, maar krijgt eveneens vrije toegang tot alle technische fiches beschikbaar van de producten in het gamma van Deschacht."),
                titleForHauraton: Multilang.getTranslation("leads.modal.hauraton.title", "Download Hauraton PDF"),
                infoForHauraton: Multilang.getTranslation("leads.modal.hauraton.info", "Vul het downloadformulier op deze pagina in en u krijgt niet enkel toegang tot deze PDF, maar krijgt eveneens vrije toegang tot alle technische fiches beschikbaar van de producten in het gamma van Deschacht."),
                downloadButton: Multilang.getTranslation("leads.modal.button.save", "Download"),
                customerTypePrompt: Multilang.getTranslation("leads.modal.label.customertype", "U bent een"),

                customerTypeCompany: Multilang.getTranslation("leads.modal.label.customertype.1", "Bedrijf"),
                customerTypePrivate: Multilang.getTranslation("leads.modal.label.customertype.2", "Consument"),

                firstNamePrompt: Multilang.getTranslation("leads.modal.label.firstname", "Voornaam"),
                firstNameError: Multilang.getTranslation("leads.modal.error.firstname", "Vul uw voornaam in"),

                lastNamePrompt: Multilang.getTranslation("leads.modal.label.lastname", "Naam"),
                lastNameError: Multilang.getTranslation("leads.modal.error.lastname", "Vul uw naam in"),

                emailPrompt: Multilang.getTranslation("leads.modal.label.email", "E-mail"),
                emailError: Multilang.getTranslation("leads.modal.error.email", "Vul een geldig e-mail adres in"),

                companyPrompt: Multilang.getTranslation("leads.modal.label.company", "Bedrijf"),
                companyError: Multilang.getTranslation("leads.modal.error.company", "Vul uw bedrijfsnaam in"),

                zipPrompt: Multilang.getTranslation("leads.modal.label.zip", "Postcode"),
                zipError: Multilang.getTranslation("leads.modal.error.zip", "Vul uw postcodein"),

                activityPrompt: Multilang.getTranslation("leads.modal.label.activity", "Activiteit"),
                activityError: Multilang.getTranslation("leads.modal.error.activity", "Wat is de activiteit van uw bedrijf ?"),
                activitySelect: Multilang.getTranslation("leads.modal.activity.select", "-- Kies --"),

                functionPrompt: Multilang.getTranslation("leads.modal.label.function", "Functie"),
                functionError: Multilang.getTranslation("leads.modal.error.function", "Wat is uw functie binnen uw bedrijf ?"),
                functionSelect: Multilang.getTranslation("leads.modal.function.select", "-- Kies --"),

                downloadPrompt: Multilang.getTranslation("leads.modal.msg.download", "Download de technische fiche hier:"),
                downloadTf: Multilang.getTranslation("leads.modal.msg.tf", "Technische fiche"),

            } as ComponentData;
        },
        props: {
            submitUrl: String,
        },
        methods: {
            submitForm() {
                const self = this;
                self.isBusy = true;

                const leadData: ContactLeadData = {
                    pageTitle: self.pageTitle,
                    firstName: self.firstName,
                    lastName: self.lastName,
                    emailAddress: self.email,
                    companyName: self.company,
                    zip: self.zip,
                    activity: self.activity,
                    sectorId: self.sectors.filter(s => { return s.code === self.activity })[0]?.id,
                    functie: self.functie,
                    functionId: self.functions.filter(s => { return s.code === self.functie })[0]?.id,
                    languageCode: Multilang.getLanguageCode(),
                    buildmat: "Deschacht",
                    initiator: self.initiateReason
                };

                SSO.storeContactLeadData(leadData);

                if (self.customerType == 2) {
                    leadData.activity = ActivityPrivate; // Particulier
                }

                axios
                    .post<ContactLeadResponse>(this.submitUrl, leadData)
                    .then((res) => res.data)
                    .then((data: ContactLeadResponse) => {
                        localStorage.setItem("ContactLeadData", JSON.stringify(leadData));
                        this.handleHubSpotFormResult();
                        this.notifyAccept();
                    })
                    .catch((err) => console.log(err));
            },

            handleHubSpotFormResult() {
                // @ts-ignore (multilang is a global object)
                this.globalMessage = Multilang.LeadsFormSuccessMessage;
                this.showDownload = true;
                this.isBusy = false;

                localStorage.setItem("LEADS", new Date().toDateString());
                
                if (navigator.userAgent.toLowerCase().indexOf("safari") > 0) {
                    const linkElement = window.document.createElement("a");
                    linkElement.id = `link${Date.now() }`;
                    window.document.body.appendChild(linkElement);
                    linkElement.setAttribute("href", this.targetUrl as string);
                    linkElement.setAttribute("target", "_blank");
                    linkElement.click();
                }
                else {
                    window.open(this.targetUrl as string, "_blank");
                }

                this.modalInstance?.hide();
            },

            isEmail(value: string): boolean {
                return emailRegExp.test(value);
            },

            validateForm(field: string, value: string) {
                const self = this;
                if (field === "firstName") {
                    self.firstNameValid = value !== null && value.length >= 2;
                    self.firstNameValidated = true;
                }

                if (field === "lastName") {
                    self.lastNameValid = value !== null && value.length >= 2;
                    self.lastNameValidated = true;
                }

                if (field === "activity") {
                    if (self.customerType == 2) {
                        self.activityValid = true;
                        self.activityValidated = true;
                    } else {
                        self.activityValid = value !== null && value.length >= 2;
                        self.activityValidated = true;
                    }
                }

                if (field === "functie") {
                    self.functionValid = value !== null && value.length >= 2;
                    self.functionValidated = true;
                }

                if (field === "email") {
                    self.emailValid = this.isEmail(value) ? true : false;
                    self.emailValidated = true;
                }

                if (field === "company") {
                    if (self.customerType == 2) {
                        self.companyValid = true;
                        self.companyValidated = true;
                    } else {
                        self.companyValid = value !== null && value.length > 2;
                        self.companyValidated = true;
                    }
                }

                if (field === "zip") {
                    if (self.customerType == 2) {
                        self.zipValid = true;
                        self.zipValidated = true;
                    } else {
                        self.zipValid = value !== null && value.length >= 4;
                        self.zipValidated = true;
                    }
                }
            },
            notifyCancel() {
                document.getElementById("content")?.dispatchEvent(new CustomEvent("cancelLeadsPopup", { detail: null }));
            },
            notifyAccept() {
                document.getElementById("content")?.dispatchEvent(new CustomEvent("acceptLeadsPopup", { detail: null }));
            }
        },
        watch: {
            // watching nested property
            firstName: function (value: string) {
                this.validateForm("firstName", value);
            },
            lastName: function (value: string) {
                this.validateForm("lastName", value);
            },
            email: function (value: string) {
                this.validateForm("email", value);
            },
            company: function (value: string) {
                this.validateForm("company", value);
            },
            zip: function (value: string) {
                this.validateForm("zip", value);
            },
            activity: function (value: string) {
                this.validateForm("activity", value);
            },
            functie: function (value: string) {
                this.validateForm("functie", value);
            },
            customerType: function (value: string) {
                if (this.customerType == 2) {
                    this.company = "";
                    this.zip = "";
                    this.activity = "";
                }
                this.validateForm("zip", value);
                this.validateForm("company", value);
                this.validateForm("activity", value);
            }
        },
        computed: {
            modalTitle(): string {
                const self = this;
                switch (self.initiateReason) {
                    case "hauraton_pdf":
                    case "hauraton_order":
                        return self.titleForHauraton;
                }
                return self.title;
            },
            modalInfo(): string {
                const self = this;
                switch (self.initiateReason) {
                    case "hauraton_pdf":
                    case "hauraton_order":
                        return self.infoForHauraton;
                }
                return self.info;
            },
            canSubmit(): boolean {
                const self = this;
                return (
                    self.lastNameValid &&
                    self.firstNameValid &&
                    self.emailValid &&
                    self.companyValid &&
                    self.zipValid &&
                    self.activityValid &&
                    self.functionValid &&
                    self.lastNameValidated &&
                    self.firstNameValidated &&
                    self.emailValidated &&
                    self.companyValidated &&
                    self.zipValidated &&
                    self.activityValidated &&
                    self.functionValidated
                );
            }
        },
        deactivated() {
            this.notifyCancel();
        },
        created() {
        },
        mounted() {
            const self = this;
            self.modalInstance = new Modal("#nt-leadsform-modal");

            document.getElementById("nt-leadsform-modal")
                ?.addEventListener("hidden.bs.modal", (event) => { self.notifyCancel(); })

            const sectorsJson = (document.querySelector(".js-sectors") as HTMLInputElement).value;
            self.sectors = JSON.parse(sectorsJson);

            const functionsJson = (document.querySelector(".js-functions") as HTMLInputElement).value;
            self.functions = JSON.parse(functionsJson);

            function startLeadsPopup(e: JQuery.ClickEvent | null) {
                const leadsFlag = localStorage.getItem("LEADS");

                if (e) {
                    e.preventDefault();
                    self.targetUrl = e.currentTarget.getAttribute("href") as string;
                }

                const leadData = SSO.loadContactLeadData();
                if (leadData != null) {
                    self.firstName = leadData.firstName;
                    self.lastName = leadData.lastName;
                    self.email = leadData.emailAddress;
                    self.company = leadData.companyName;
                    self.activity = leadData.activity;
                    self.functie = leadData.functie;
                    self.zip = leadData.zip;
                }

                /*if (leadsFlag !== null) {
                    self.submitForm();
                    self.notifyAccept();
                    return true;
                }*/

                self.modalInstance?.show();
            }

            $("body").on("click", ".js-activate-leadsform", startLeadsPopup);

            document.getElementById("content")?.addEventListener("startLeadsPopup", (e: Event) => {
                var ce = e as CustomEvent;
                this.initiateReason = ce.detail ?? "product_document";
                startLeadsPopup(null);
            });
        },
        setup: (props) => {
            Multilang.load();
            let submitUrl = props.submitUrl as string;
            let pageTitle = document.title;
            let targetUrl = "" as string;
            let modalInstance: any = null;

            return { submitUrl, pageTitle, targetUrl, modalInstance }
        },

    });

</script>

<style lang="scss" scoped>
</style>
